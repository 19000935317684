@import '~@angular/material/_theming';
@import 'common';
@include mat-core();
$primary-palette: mat-palette($material-primary-color);
$accent-palette: mat-palette($material-accent-color);
$theme: mat-light-theme($primary-palette, $accent-palette);
@include angular-material-theme($theme);

.dark-theme {
  $dark-primary: mat-palette($material-primary-color);
  $dark-accent: mat-palette($material-accent-color);
  $dark-theme: mat-dark-theme($dark-primary, $dark-accent);
  @include angular-material-theme($dark-theme);
}

@include iu-palette($primary-palette, $accent-palette);

iu-grid {
  @include grid-palette($primary-palette);

  .grid .k-grid-header tr:first-child .k-header {
    @include dark-blue-gradient();
  }

  .grid th {
    font-size: 1.2em;
  }
}

iu-dialog {
  @include dialog-palette($primary-palette, $accent-palette);
}

iu-file-upload {
  @include file-upload-palette($primary-palette, $accent-palette);

  .file-upload {
    .box {
      padding: 20px 20px;

      #dragdrop-message {
        top: 0px;
      }
    }

    .custom-file-upload {
      margin-bottom: 10px;

      figure {
        height: 30px;
        width: 30px;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.inline-tags {
  mat-chip-list {
    .mat-chip-list-wrapper {
      margin: -2px 0px;
    }
  }
}

@include scrollable(#fff, $primary-color);
@include dialog-theme($theme);
@include editor-theme($theme);
